<template>
  <div class="maintenance-list">
    <div class="box-container">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="任务单号" prop="maintenancordernum">
              <a-input v-model.trim="queryParams.maintenancordernum" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="节点状态" prop="processstep">
              <a-select v-model="queryParams.processstep">
                <a-select-option v-for="(item, index) in orderstepOptions" :key="index" :value="item.value">{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <div class="query-btns">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <!-- <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button> -->
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">维修任务</h3>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="maintenancorderid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="maintenancordernum" slot-scope="value, record" @click="showRepairDetailModal(record)" style="color: #1890ff;cursor: pointer;">
            <span v-if="record.overdue" class="table-dot" style="background-color: #ff4d4f;"></span>
            <a-tooltip :title="value">
              <span>{{value}}</span>
            </a-tooltip>
          </span>
          <span slot="creattime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="needfinishtime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDD').format('YYYY-MM-DD') : ''">
              <span>{{value ? moment(value, 'YYYYMMDD').format('YYYY-MM-DD') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="processstep" slot-scope="value">
            <a-tag :color="orderstepscolorMap[value]||''">{{orderstepsMap[value]||''}}</a-tag>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <repair-task-detail-modal :visible.sync="repairTaskDetailModalVisible" :detail-data="repairTaskModalDetailData"></repair-task-detail-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
import { getItemFromArrayByKey } from 'U'
import store from '@/store'
import deptselect from '@/mixins/deptselect'
import pagination from '@/mixins/pagination'
import maintenanceTypes from '@/json/maintenanceTypes'
import orderstepOptions from '@/json/orderstepOptions'
import orderstepsMap from '@/json/ordersteps'
import orderstepscolorMap from '@/json/orderstepscolor'
import { getMentenacegroupListByCondition } from 'A/xtpz.js'
import { findMaintenanceorderListByCondition, deleteMaintenanceorder, cancelMaintenanceorder } from 'A/wbgl.js'
import RepairTaskDetailModal from 'V/wbgl/repairTask/DetailModal'

export default {
  name: 'maintenanceTask',
  mixins: [deptselect, pagination],
  components: {
    RepairTaskDetailModal,
  },
  props: ['liftid'],
  data() {
    return {
      moment,
      maintenanceTypes,
      orderstepOptions,
      orderstepsMap,
      orderstepscolorMap,
      queryParams: {
        maintenancordertype: '1',
        maintenancordernum: '',
        processstep: '',
      },
      tableColumns: [
        {
          title: '任务单号',
          dataIndex: 'maintenancordernum',
          key: 'maintenancordernum',
          ellipsis: true,
          scopedSlots: { customRender: 'maintenancordernum' }
        },
        {
          title: '电梯编码',
          dataIndex: 'liftnum',
          key: 'liftnum',
          ellipsis: true,
        },
        {
          title: '电梯救援码',
          dataIndex: 'liftrescue',
          key: 'liftrescue',
          ellipsis: true,
        },
        {
          title: '安装地址',
          dataIndex: 'adress',
          key: 'adress',
          ellipsis: true,
        },
        {
          title: '使用单位',
          dataIndex: 'userdepname',
          key: 'userdepname',
          ellipsis: true,
        },
        {
          title: '报修时间',
          dataIndex: 'creattime',
          key: 'creattime',
          ellipsis: true,
          scopedSlots: { customRender: 'creattime' }
        },
        {
          title: '应完成日期',
          dataIndex: 'needfinishtime',
          key: 'needfinishtime',
          ellipsis: true,
          scopedSlots: { customRender: 'needfinishtime' }
        },
        {
          title: '节点状态',
          dataIndex: 'processstep',
          key: 'processstep',
          ellipsis: true,
          scopedSlots: { customRender: 'processstep' }
        },
      ],
      tableData: [],
      tableLoading: false,
      repairTaskDetailModalVisible: false,
      repairTaskModalDetailData: null,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    maintenanceTypesMap() {
      let result = {};
      this.maintenanceTypes.forEach(item => {
        result[item.value] = item.name;
      })
      return result;
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if(this.liftid) {
        this.getTableData();
      }
    },
    resetQueryParams() {
      this.$refs.queryForm.resetFields();
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        liftid: this.liftid,
        ...this.queryParams,
        isnotauto:"true",
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      findMaintenanceorderListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item.map(item => {
            if(item.processstep != '81') {
              if(item.needfinishtime) {
                if(moment() > moment(item.needfinishtime, 'YYYYMMDD')) {
                  item.overdue = true;
                }
              }
            }
            return item;
          });
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    showRepairDetailModal(record) {
      this.repairTaskModalDetailData = record;
      this.repairTaskDetailModalVisible = true;
    }
  }
}
</script>

<style lang="scss" scoped>
.box-container {
  padding: 0;
}
</style>