<template>
  <div class="maintenance-list">
    <div class="box-container">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">审核记录</h3>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="examineid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="examinetime" slot-scope="value">{{moment(value, 'YYYYMMDD').format('YYYY-MM-DD')}}</span>
          <span slot="nextexaminetime" slot-scope="value">{{moment(value, 'YYYYMMDD').format('YYYY-MM-DD')}}</span>
          <span slot="result" slot-scope="value">{{value=='1'?'不合格':'合格'}}</span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" @change="pageChange" style="margin-top: 20px; text-align: right;">
        <template slot="buildOptionText" slot-scope="props">
          <span>{{ props.value }}条/页</span>
        </template>
      </a-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import { findExamineListByCondition } from 'A/jcgn'
export default {
  props: {
    liftid: {
      default: ''
    }
  },
  data() {
    return {
      moment,
      tableLoading: false,
      tableColumns: [
        {
          title: '检测机构',
          dataIndex: 'examinedepname',
          key: 'examinedepname'
        },
        {
          title: '检测人员',
          dataIndex: 'examineperson',
          key: 'examineperson'
        },
        {
          title: '检测时间',
          dataIndex: 'examinetime',
          key: 'examinetime',
          scopedSlots: { customRender: 'examinetime' }
        },
        {
          title: '下次检测时间',
          dataIndex: 'nextexaminetime',
          key: 'nextexaminetime',
          scopedSlots: { customRender: 'nextexaminetime' }
        },
        {
          title: '检测结论',
          dataIndex: 'result',
          key: 'result',
          scopedSlots: { customRender: 'result' }
        },
      ],
      tableData: [],
      currentPage: 1,
      pagination: {
        total: 0,
        pageSize: 5,
      }
    }
  },
  created() {
    this.getTableData();
  },
  methods: {
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      let params = {
        liftid: this.liftid,
        examinetype: '5',
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize,
      };
      findExamineListByCondition(params).then(res => {
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      })
    },
    pageChange(page, size) {
      this.getTableData();
    },
  }
}
</script>